@tailwind base;
@tailwind components;
@tailwind utilities;

.table-wrp {
  max-height: 85vh;
  overflow-y: auto;
  display: block;
}
thead {
  position: sticky;
  top: 0;
}
